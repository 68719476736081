import { ISSUE_TYPES, STATUS_CLASS_MAPPING } from "@/constants"

export default {
  name : "Tasks",
  props: {
    pCanAddTask  : Boolean,
    pTasks       : Array,
    pIsTaskAdded : Boolean,
    pIsAddingTask: Boolean,
    pUsers       : Array,
    pStatuses    : Array
  },
  data: () => ({
    task: {
      summary: null,
      typeId : ISSUE_TYPES[1].id
    },
    submitEventType: undefined,
    isTasksExpanded: false,
    addTask        : false
  }),
  computed: {
    hasTasks() {
      return !!this.pTasks.length
    },
    getAddTaskButtonLabel() {
      return this.hasTasks ? this.$t("1265") : this.$t("1263")
    }
  },
  methods: {
    getClassForStatusChip(statusId) {
      return STATUS_CLASS_MAPPING[statusId]
    },
    isUserDisabled(assigneeId) {
      return this.pUsers.find(user => user.id === assigneeId)?.disabledButNotDeleted
    },
    getUserNameForATask(assigneeId) {
      return assigneeId ? this.pUsers.find(user => user.id === assigneeId)?.name : "Unassigned"
    },
    getStatusForATask(statusId) {
      return this.pStatuses.find(status => status.id === statusId)?.name
    },
    toggleExpandCollapseTasks() {
      this.isTasksExpanded = !this.isTasksExpanded
    },
    handleAddTaskOnSave() {
      this.$emit("addTasks", this.task)
      this.task.summary = null
    },
    handleAddTaskOnEnter() {
      if (this.task.summary) {
        this.submitEventType = "enter"
        this.handleAddTaskOnSave()
      }
    },
    handleCancelTask() {
      this.addTask      = false
      this.task.summary = null
    }
  },
  watch: {
    pIsTaskAdded: {
      immediate: true,
      handler  : async function(newValue) {
        if (newValue) {
          if (this.submitEventType === "enter") {
            this.submitEventType = undefined
          } else {
            this.addTask = false
          }
        }
      }
    }
  }
}