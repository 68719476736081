import ThemisReadOnly from "@/components/shared/read-only"
import { ARRAY } from "@/constants"

export default {
  name      : "ReportIntakeForms",
  components: {
    ThemisReadOnly
  },
  data: () => ({
  }),
  props: {
    pFormInstance: Object
  },
  computed: {
  },
  methods: {
    isValueNull(values) {
      if (values.length === 1) {
        if (values[ARRAY.FIRST] === null) {
          return true
        }
      }
    },
    getFormInstanceFieldValueForDisplay(formInstanceFieldValues) {
      const values = formInstanceFieldValues.map(formInstanceFieldValue => formInstanceFieldValue.value)
      if (this.isValueNull(values)) {
        return this.$t("1018")
      }
      return values.toString()
    }
  },
  watch: {
  }
}