import ThemisInput from "@/components/shared/input"
import { FIELD_TYPES, WIDGET, ARRAY, MAX_CHARACTER_LIMIT } from "@/constants"
import ThemisDateTimePicker from "@/components/shared/date-time-picker"
import ThemisCascadedInput from "@/components/shared/cascaded-input"

export default {
  name      : "IssueFormInstance",
  components: {
    ThemisInput,
    ThemisCascadedInput,
    ThemisDateTimePicker
  },
  props: {
    pFormInstance              : Object,
    pOptionListItems           : Array,
    pIsUpdatingFormInstance    : Boolean,
    pIsFormInstanceUpdated     : Boolean,
    pFormInstanceUpdatePolicies: Array,
    pFormInstanceRemovePolicies: Array
  },
  data: () => {
    return {
      formInstanceFieldValues            : {},
      updatingFormInstanceFieldId        : null,
      focussedLongTextFormInstanceFieldId: null,
      longTextFieldCharacterLimit        : MAX_CHARACTER_LIMIT.ISSUE_FORM_LONG_TEXT_FIELD,
      shortTextFieldCharacterLimit       : MAX_CHARACTER_LIMIT.ISSUE_FORM_SHORT_TEXT_FIELD,
      isDateTimePickerOpen               : {},
      showIssueFormInstanceDateMenu      : {}
    }
  },
  computed: {
    sortedFormInstanceFields() {
      let sortedFields = []

      if (this.pFormInstance.formInstanceFields?.length) {
        sortedFields = [...this.pFormInstance.formInstanceFields].sort(
          (field1, field2) => field1.sortingOrder - field2.sortingOrder
        )
      }

      return sortedFields
    },
    currentFormInstanceUpdatePolicies() {
      return this.pFormInstanceUpdatePolicies.find(formInstanceUpdatePolicy =>
        formInstanceUpdatePolicy.id === this.pFormInstance.id
      )
    },
    canUpdateFormInstanceFieldValue() {
      return this.currentFormInstanceUpdatePolicies?.set?.value !== undefined
    },
    currentFormInstanceRemovePolicies() {
      return this.pFormInstanceRemovePolicies.find(issueFormInstanceRemovePolicy =>
        issueFormInstanceRemovePolicy.id === this.pFormInstance.id
      )
    },
    canRemoveFormInstance() {
      return this.currentFormInstanceRemovePolicies?.set?.remove !== undefined
    }
  },
  methods: {
    isOptionListCascaded(formInstanceField) {
      const filteredOptionListItems = this.pOptionListItems.filter(optionList =>
        optionList.optionListId === formInstanceField.optionListId)
      return !!filteredOptionListItems.find(optionListItem => optionListItem.parentId)?.parentId
    },
    getItemsForSelectionCascadedOptionList(formInstanceField) {
      if ([FIELD_TYPES.OPTION_LIST.value, FIELD_TYPES.MULTIPLE_OPTION_LIST.value].includes(formInstanceField.type)) {
        const sortedOptionListItems = this.pOptionListItems
          .filter(optionListItem => optionListItem.optionListId === formInstanceField.optionListId)
          .sort((optionListItem1, optionListItem2) => optionListItem1.sortingOrder - optionListItem2.sortingOrder)
          .map(optionListItem => ({
            id      : optionListItem.id,
            name    : optionListItem.name,
            parentId: optionListItem.parentId
          }))

        const isMultiple = this.isFieldTypeMultipleOptionList(formInstanceField)
        const options    = this.generateNestedOptionListItems(sortedOptionListItems, isMultiple)

        let currentIssueFormInstanceFieldValue
        if (this.isFieldTypeMultipleOptionList(formInstanceField) ||
          this.isFieldTypeSingleOptionList(formInstanceField)) {
          currentIssueFormInstanceFieldValue = this.formInstanceFieldValues[formInstanceField.id]
        } else {
          currentIssueFormInstanceFieldValue = [this.formInstanceFieldValues[formInstanceField.id]]
        }

        for (const issueFormInstanceFieldValue of currentIssueFormInstanceFieldValue) {
          const isOptionListItemExists = sortedOptionListItems.find(option =>
            option.name === issueFormInstanceFieldValue
          )
          if (!isOptionListItemExists && issueFormInstanceFieldValue) {
            options.push({ name: issueFormInstanceFieldValue, children: [] })
          }
        }
        return options
      }
    },
    getItemsForSelection(formInstanceField) {
      if ([FIELD_TYPES.OPTION_LIST.value, FIELD_TYPES.MULTIPLE_OPTION_LIST.value].includes(formInstanceField.type)) {
        const options = this.pOptionListItems
          .filter(optionListItem => optionListItem.optionListId === formInstanceField.optionListId)
          .sort((optionListItem1, optionListItem2) => optionListItem1.sortingOrder - optionListItem2.sortingOrder)
          .map(optionListItem => ({
            id  : optionListItem.id,
            name: optionListItem.name
          }))

        let currentIssueFormInstanceFieldValue
        if (formInstanceField.type === FIELD_TYPES.MULTIPLE_OPTION_LIST.value) {
          currentIssueFormInstanceFieldValue = this.formInstanceFieldValues[formInstanceField.id]
        } else {
          currentIssueFormInstanceFieldValue = [this.formInstanceFieldValues[formInstanceField.id]]
        }

        for (const issueFormInstanceFieldValue of currentIssueFormInstanceFieldValue) {
          const isOptionListItemExists = options.find(option =>
            option.name === issueFormInstanceFieldValue
          )
          if (!isOptionListItemExists && issueFormInstanceFieldValue) {
            options.push({ name: issueFormInstanceFieldValue })
          }
        }
        return options
      }
    },
    onNodeSelect(selectedNodes, formInstanceField) {
      this.formInstanceFieldValues[formInstanceField.id] = selectedNodes
      this.updateFormInstanceFieldValue(formInstanceField)
    },
    generateNestedOptionListItems(data, isMultiple) {
      const idToObject = {}
      const result     = []

      data.forEach(item => {
        idToObject[item.id] = { ...item, children: [] }
      })

      data.forEach(item => {
        const parent = idToObject[item.parentId]
        if (parent) {
          parent.disabled = !isMultiple
          parent.children.push(idToObject[item.id])
        } else {
          result.push(idToObject[item.id])
        }
      })

      return result
    },
    getFormInstanceFieldValue(formInstanceField) {
      let fieldValue

      if (this.isFieldTypeMultipleOptionList(formInstanceField) ||
        (this.isFieldTypeSingleOptionList(formInstanceField) && this.isOptionListCascaded(formInstanceField))) {
        fieldValue = formInstanceField.formInstanceFieldValues
          .filter(
            formInstanceFieldValue => formInstanceFieldValue.value
          ).map(
            formInstanceFieldValue => formInstanceFieldValue.value
          )
      } else {
        fieldValue = formInstanceField.formInstanceFieldValues[ARRAY.FIRST]?.value
      }
      if (formInstanceField.type === FIELD_TYPES.DATE_TIME.value) {
        return fieldValue ? new Date(fieldValue) : null
      }
      return fieldValue
    },
    hasFormInstanceFieldValueChanged(formInstanceField) {
      const originalIssueFormInstanceFieldValue = this.getFormInstanceFieldValue(formInstanceField)
      if (formInstanceField.type === FIELD_TYPES.MULTIPLE_OPTION_LIST.value) {
        return JSON.stringify(originalIssueFormInstanceFieldValue.slice().sort()) !==
          JSON.stringify(this.formInstanceFieldValues[formInstanceField.id].slice().sort())
      }
      return this.formInstanceFieldValues[formInstanceField.id] !== originalIssueFormInstanceFieldValue
    },
    isFormInstanceFieldUpdating(formInstanceField) {
      return this.pIsUpdatingFormInstance && this.updatingFormInstanceFieldId === formInstanceField.id
    },
    isFieldTypeMultipleOptionList(formInstanceField) {
      return formInstanceField.type === FIELD_TYPES.MULTIPLE_OPTION_LIST.value
    },
    isFieldTypeSingleOptionList(formInstanceField) {
      return formInstanceField.type === FIELD_TYPES.OPTION_LIST.value
    },
    handleOptionListInputOnBlurEvent(onBlur, formInstanceField) {
      onBlur()
      if (this.isFieldTypeMultipleOptionList(formInstanceField)) {
        this.updateFormInstanceFieldValue(formInstanceField)
      }
    },
    handleOptionListInputOnChangeEvent(formInstanceField) {
      if (!this.isFieldTypeMultipleOptionList(formInstanceField)) {
        this.updateFormInstanceFieldValue(formInstanceField)
      }
    },
    updateFormInstanceFieldValue(formInstanceField) {
      if (formInstanceField.type === FIELD_TYPES.DATE.value) {
        this.showIssueFormInstanceDateMenu[formInstanceField.id] = false
      }
      const fieldValues = this.formInstanceFieldValues[formInstanceField.id]
      if (this.hasFormInstanceFieldValueChanged(formInstanceField)) {
        const dataToBeUpdated               = []
        const isFieldTypeMultipleOptionList = this.isFieldTypeMultipleOptionList(formInstanceField)
        const isFieldTypeSingleOptionList   = this.isFieldTypeSingleOptionList(formInstanceField)

        if (isFieldTypeMultipleOptionList ||
          (isFieldTypeSingleOptionList && this.isOptionListCascaded(formInstanceField))) {
          if (fieldValues.length) {
            for (const fieldValue of fieldValues) {
              dataToBeUpdated.push({
                formInstanceFieldId: formInstanceField.id,
                value              : fieldValue
              })
            }
          } else {
            dataToBeUpdated.push({
              formInstanceFieldId: formInstanceField.id,
              value              : null
            })
          }
        } else {
          dataToBeUpdated.push({
            formInstanceFieldId: formInstanceField.id,
            value              : fieldValues
          })
        }

        this.$emit("updateFormInstance", {
          id  : this.pFormInstance.id,
          data: dataToBeUpdated
        })
        this.updatingFormInstanceFieldId = formInstanceField.id
      }
    },
    isFormInstanceFieldWidgetTextarea(formInstanceField) {
      return formInstanceField.widget === WIDGET.TEXTAREA
    },
    displayLongTextFieldActions(formInstanceField) {
      return this.focussedLongTextFormInstanceFieldId === formInstanceField.id ||
        this.hasFormInstanceFieldValueChanged(formInstanceField)
    },
    handleLongTextFieldFocus(formInstanceField, onFocus) {
      onFocus()
      this.focussedLongTextFormInstanceFieldId = formInstanceField.id
    },
    handleNumberFieldUpdateEvent(formInstanceField, onBlur) {
      if (onBlur) {
        onBlur()
      }
      const formInstanceFieldValue = this.formInstanceFieldValues[formInstanceField.id]
      const isNumber               = Number.isInteger(formInstanceFieldValue)
      const isEmptyString          = formInstanceFieldValue?.toString().length === 0

      if (isNumber) {
        this.formInstanceFieldValues[formInstanceField.id] =
          this.formInstanceFieldValues[formInstanceField.id].toString()
      } else if (isEmptyString) {
        this.formInstanceFieldValues[formInstanceField.id] = null
      }

      this.updateFormInstanceFieldValue(formInstanceField)
    },
    handleNumberFieldKeypress(event, formInstanceField) {
      const formInstanceFieldValue        = this.formInstanceFieldValues[formInstanceField.id]
      const isFormInstanceFieldValueEmpty = !formInstanceFieldValue && formInstanceFieldValue !== 0
      const isKeyPressedADash             = event.key === "-"
      const isKeyPressedNotANumber        = isNaN(event.key)
      if (isKeyPressedNotANumber) {
        if (!(isFormInstanceFieldValueEmpty && isKeyPressedADash)) {
          event.preventDefault()
        }
      }
    },
    handleLongTextFieldCancelSave(formInstanceField) {
      this.focussedLongTextFormInstanceFieldId           = null
      this.formInstanceFieldValues[formInstanceField.id] = this.getFormInstanceFieldValue(formInstanceField)
    },
    handleUpdateDateTime(formInstanceField) {
      this.isDateTimePickerOpen[formInstanceField.id] = false
      this.updateFormInstanceFieldValue(formInstanceField)
    },
    handleResetDateTimePicker(formInstanceField) {
      this.formInstanceFieldValues[formInstanceField.id] = this.getFormInstanceFieldValue(formInstanceField)
    },
    handleCloseDateTimePicker(formInstanceField) {
      this.isDateTimePickerOpen[formInstanceField.id] = false
      this.handleResetDateTimePicker(formInstanceField)
    },
    dateTimeForDisplaying(formInstanceField) {
      if (this.formInstanceFieldValues[formInstanceField.id]) {
        return this.$moment(this.formInstanceFieldValues[formInstanceField.id]).format("D MMMM YYYY HH:mm (UTCZ)")
      }
    },
    handleShortTextFieldUpdateEvent(formInstanceField, onBlur) {
      onBlur()
      const shortTextFieldValue               = this.formInstanceFieldValues[formInstanceField.id]
      const isShortTextFieldNullOrWithinLimit = !shortTextFieldValue ||
        shortTextFieldValue.length <= this.shortTextFieldCharacterLimit

      if (isShortTextFieldNullOrWithinLimit) {
        this.updateFormInstanceFieldValue(formInstanceField)
      }
    },
    handleShortTextFieldInputOnEnter(formInstanceFieldId) {
      this.$refs[`input_short_text_field_${formInstanceFieldId}`][ARRAY.FIRST].blur()
    },
    handleDateClearEvent(formInstanceField) {
      this.formInstanceFieldValues[formInstanceField.id] = null
      this.updateFormInstanceFieldValue(formInstanceField)
    },
    handleDateTimeClearEvent(formInstanceField) {
      this.formInstanceFieldValues[formInstanceField.id] = null
      this.updateFormInstanceFieldValue(formInstanceField)
    },
    issueFormInstanceDateDisplay(formInstanceField) {
      let displayDate = this.formInstanceFieldValues[formInstanceField.id]
      if (displayDate) {
        displayDate = this.$moment(displayDate).format("DD MMMM YYYY")
      }
      return displayDate
    }
  },
  watch: {
    pFormInstance: {
      immediate: true,
      handler  : function(formInstance) {
        const formInstanceFieldValues       = {}
        const showIssueFormInstanceDateMenu = {}
        for (const formInstanceField of formInstance.formInstanceFields) {
          formInstanceFieldValues[formInstanceField.id] = this.getFormInstanceFieldValue(formInstanceField)
          if (formInstanceField.type === FIELD_TYPES.DATE.value) {
            showIssueFormInstanceDateMenu[formInstanceField.id] = false
          }
        }
        this.formInstanceFieldValues       = { ...formInstanceFieldValues }
        this.showIssueFormInstanceDateMenu = { ...showIssueFormInstanceDateMenu }
      }
    },
    pIsFormInstanceUpdated: {
      immediate: true,
      handler  : function(value) {
        if (value) {
          this.updatingFormInstanceFieldId         = null
          this.focussedLongTextFormInstanceFieldId = null
        }
      }
    }
  }
}