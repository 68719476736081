import ThemisInput from "@/components/shared/input"
import { compareDates } from "@/utils"
import ThemisDecision from "@/components/shared/decision"
export default {
  name      : "IssueLinks",
  components: {
    ThemisInput,
    ThemisDecision
  },
  data: () => ({
    isIssueLinksExpanded       : false,
    selectedIssues             : [],
    showRemoveLinkedIssueDialog: false,
    issueLinkToBeRemoved       : undefined
  }),
  props: {
    pIssues                 : Array,
    pIssue                  : Object,
    pIssueLinks             : Array,
    pIssueLinkAddPolicies   : Array,
    pIsIssueLinkAdded       : Boolean,
    pIsAddingIssueLinks     : Boolean,
    pIssueLinkRemovePolicies: Array,
    pIsRemovingIssueLink    : Boolean,
    pIsIssueLinkRemoved     : Boolean
  },
  methods: {
    toggleExpandCollapseIssueLinks() {
      this.isIssueLinksExpanded = !this.isIssueLinksExpanded
    },
    handleIssueSelectionOnBlurEvent(onBlur) {
      onBlur()
      if (this.selectedIssues.length) {
        const issuesToLink = this.selectedIssues.map(selectedIssueId => ({
          fromId: this.pIssue.id,
          toId  : selectedIssueId
        }))
        this.$emit("addIssueLinks", issuesToLink)
      }
    },
    canAddIssueLink(issueId = this.pIssue.id) {
      const currentIssueAddLinkPolicy = this.pIssueLinkAddPolicies.find(issueLinkAddPolicy =>
        issueLinkAddPolicy.id === issueId)
      return currentIssueAddLinkPolicy && currentIssueAddLinkPolicy?.select !== undefined
    },
    getSummaryForAnIssue(issueId) {
      const summary = this.pIssues.find(issue => issue.id === issueId)?.summary
      return summary ? ` - ${summary}` : ""
    },
    navigateToIssue(issueId) {
      this.$router.push({ name: "issue", params: { id: issueId } })
      this.$vuetify.goTo(0, {
        duration: 500,
        offset  : 0,
        easing  : "easeInOutCubic"
      })
    },
    openRemoveIssueLinkDecision(issueLinkToBeRemoved) {
      this.issueLinkToBeRemoved        = issueLinkToBeRemoved
      this.showRemoveLinkedIssueDialog = true
    },
    closeRemoveIssueLinkDecision() {
      this.showRemoveLinkedIssueDialog = false
      this.issueLinkToBeRemoved        = undefined
    },
    canRemoveIssueLink(linkedIssueId) {
      const currentIssueRemoveLinkPolicy = this.pIssueLinkRemovePolicies.find(issueLinkRemovePolicy =>
        issueLinkRemovePolicy.id === linkedIssueId)
      return currentIssueRemoveLinkPolicy && currentIssueRemoveLinkPolicy?.set?.remove !== undefined
    },
    canViewIssueLink(issueId) {
      return this.arrayOfIssueIds.includes(issueId)
    },
    getTextForAnIssue(issueId) {
      if (this.canViewIssueLink(issueId)) {
        return this.getSummaryForAnIssue(issueId)
      }
      return ` - ${this.$t("1255")}`
    }
  },
  computed: {
    arrayOfIssueIds() {
      return this.pIssues.map(issue => issue.id)
    },
    issuesToLink() {
      const linkedIssueIds = [this.pIssue.id]
      linkedIssueIds.push(...this.linkedIssues.map(linkedIssue => linkedIssue.issueId))
      return this.pIssues.filter(issue => !linkedIssueIds.includes(issue.id)).map(issue => {
        if(this.canAddIssueLink(issue.id)) {
          const id = issue.id
          let name = id
          if(issue.summary) {
            name +=  ` - ${issue.summary}`
          }
          return {
            id,
            name
          }
        }
      })
    },
    linkedIssues() {
      const linkedIssueIds = []
      const issueLinks     = [...this.pIssueLinks].sort((first, second) => {
        return compareDates(second.createdAt, first.createdAt)
      })
      if (issueLinks?.length) {
        issueLinks.forEach(issueLink => {
          if (issueLink.fromId !== this.pIssue.id &&
            !linkedIssueIds.some(linkedIssue => linkedIssue.issueId === issueLink.fromId)) {
            linkedIssueIds.push({ id: issueLink.id, issueId: issueLink.fromId })
          } else if (issueLink.toId !== this.pIssue.id &&
            !linkedIssueIds.some(linkedIssue => linkedIssue.issueId === issueLink.toId)) {
            linkedIssueIds.push({ id: issueLink.id, issueId: issueLink.toId })
          }
        })
      }
      return linkedIssueIds
    },
    linkedIssuesForDisplay() {
      return this.linkedIssues.map(linkedIssue => ({
        id     : linkedIssue.id,
        issueId: linkedIssue.issueId,
        text   : this.getTextForAnIssue(linkedIssue.issueId)
      }))
    }
  },
  watch: {
    pIsIssueLinkAdded: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue) {
          this.selectedIssues = []
        }
      }
    },
    pIsIssueLinkRemoved: {
      handler: function(newValue) {
        if (newValue) {
          this.$emit("issueLinkRemoved", this.issueLinkToBeRemoved)
          this.showRemoveLinkedIssueDialog = false
          this.issueLinkToBeRemoved        = undefined
        }
      }
    },
    pIsRemovingIssueLink: {
      handler: function(newValue) {
        this.$DECISIONS.REMOVE_ISSUE_LINK.pActions[0].buttonProps.disabled = newValue
        this.$DECISIONS.REMOVE_ISSUE_LINK.pActions[1].buttonProps.loading  = newValue
      }
    }
  }
}
